
const SubscriptionUpdateType = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  RECREATE: 'RECREATE',
  UPDATE: 'UPDATE',
  NOTHING: 'NOTHING',
}

export default SubscriptionUpdateType;
