import SubscriptionUpdateType from "./SubscriptionUpdateType";
import {createSubscription, deleteSubscription} from "../../../firebase-services/firestore";

const SubscriptionUpdateProcessor = async ({type, payload}) => {
  const SUT = SubscriptionUpdateType;
  if (type === SUT.NOTHING) {
    return;
  }
  if (type === SUT.DELETE || type === SUT.RECREATE) {
    await deleteSubscription(payload);
  }
  if (type === SUT.CREATE || type === SUT.RECREATE || type === SUT.UPDATE) {
    await createSubscription(payload);
  }
}

export default SubscriptionUpdateProcessor;
