import {Breadcrumbs, Link, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import styles from "./Breadcrumbs.module.css";

const CompanyBreadcrumbs = props => {
  const history = useHistory();
  const name = props.name ?? 'Add New';
  return <Breadcrumbs aria-label="breadcrumb" className={styles.breadcrumbs}>
    <Link color="inherit" href="/companies" onClick={() => history.push('/companies')}>
      Companies
    </Link>
    <Typography color="textPrimary"> {name} </Typography>
  </Breadcrumbs>;
}

export default CompanyBreadcrumbs;
