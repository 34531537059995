import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {signInWithEmailAndPassword, signOutOfFirebase} from '../../firebase-services/auth';
import { initFirebase } from "../../firebase-services";

initFirebase();

export const attemptSignIn = createAsyncThunk(
  'auth/attemptSignIn',
  async (_, thunkAPI) => {
    const {email, password, rememberMe} = thunkAPI.getState().auth;
    return await signInWithEmailAndPassword(email, password, rememberMe);
  }
)

export const slice = createSlice({
  name: 'auth',
  initialState: {
    isInitialized: false,
    isSuccess: false,
    isFetching: false,
    isError: false,
    rememberMe: false,
    errorMessage: '',
    email: '',
    password: '',
  },
  reducers: {
    authLoaded: (state, {payload}) => {
      state.isInitialized = true;
      state.isSuccess = payload;
    },
    signOut: () => {
      signOutOfFirebase();
    },
    signOutComplete: state => {
      state.isError = false;
      state.errorMessage = '';
      state.isSuccess = false;
      state.isFetching = false;
      state.isInitialized = false;
    },
    setEmail: (state, {payload}) => {
      state.email = payload;
    },
    setPassword: (state, {payload}) => {
      state.password = payload;
    },
    setRememberMe: (state, {payload}) => {
      state.rememberMe = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(attemptSignIn.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(attemptSignIn.rejected, (state, error) => {
        state.isError = true;
        state.errorMessage = error.error.message;
        state.isFetching = false;
      })
      .addCase(attemptSignIn.fulfilled, (state) => {
        state.isFetching = false;
        state.isSuccess = true;
      });
  },
});

export const { authLoaded, signOut, setEmail, setPassword, setRememberMe } = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };



// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const authState = state => state.auth;
export const isLoggedInSelector = state => state.auth?.isInitialized && state.auth?.isSuccess;

export default slice.reducer;
