const combineStates = (state1, state2) => {
  const combined = Object.assign({}, state1);
  Object.entries(state2)
    .forEach(([prop, value]) => {
      if (value !== null) {
        combined[prop] = value;
      }
    })
  return combined;
}

export default combineStates;
