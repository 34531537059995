import {Button, Checkbox, CircularProgress, FormControlLabel, Paper, TextField, Typography} from "@material-ui/core";
import {attemptSignIn, authState, setEmail, setPassword, setRememberMe} from "./authSlice";
import {useDispatch, useSelector} from "react-redux";
import MuiAlert from '@material-ui/lab/Alert';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import styles from './SginIn.module.css';

export const SignIn = () => {
  const state = useSelector(authState);
  const dispatch = useDispatch();

  const signInSubmit = (event) => {
    event.preventDefault();
    dispatch(attemptSignIn());
  }
  const loading = state.isFetching;
  return (state.isInitialized) ? (
    <Paper elevation={3} className={styles.SignInContainer}>
      <form noValidate className={styles.SignInForm} onSubmit={signInSubmit}>
        <Typography variant="h1" component="h2" gutterBottom>
          Hard Hat VR
        </Typography>
        <Typography variant="h2" gutterBottom>
          Sign-in
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          disabled={loading}
          autoFocus
          onInput={e => dispatch(setEmail(e.target.value))}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          disabled={loading}
          onInput={e => dispatch(setPassword(e.target.value))}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.rememberMe}
              onChange={e => dispatch(setRememberMe(e.target.checked))}
              color="primary"
            />
          }
          label="Remember me"
        />
        { state.isError ? <MuiAlert className={styles.SignInError} elevation={6} variant="filled" severity="error"> {state.errorMessage} </MuiAlert> : '' }
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
          size="large"
          startIcon={<LockOpenIcon />}
        >
          Sign In
        </Button>
      </form>
    </Paper>
  ) : (<CircularProgress className={styles.loader}/>)
}
