import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { initFirebase } from "../../../firebase-services";
import {getSubscription} from "../../../firebase-services/firestore";
import SubscriptionUpdateType from "./SubscriptionUpdateType";
import equal from "fast-deep-equal/es6";

initFirebase();

export const loadSubscription = createAsyncThunk(
  'subscription/loadSubscription',
  async key => await getSubscription(key)
)

const validateKey = key => (typeof key !== 'string' || key.length < 6)
  ? 'Key must be at least 6 characters long'
  : null;

const validateExpires = expires => (typeof expires !== 'string')
  ? 'Expiration date must be set'
  : null;

export const slice = createSlice({
  name: 'subscription',
  initialState: {
    data: null,
    withLocalChanges: null,
    isFetching: false,
    isError: false,
    errorMessage: '',
    courseLanguageTab: 'En',
    expiresValidationError: null,
    keyValidationError: null,
    isEnabled: false,
  },
  reducers: {
    setEnabled: (state, {payload}) => {
      state.isEnabled = payload;
      if (state.isEnabled) {
        state.expiresValidationError = validateExpires(state.withLocalChanges?.expires);
        state.keyValidationError = validateKey(state.withLocalChanges?.key);
      }
    },
    clearSubscription: (state) => {
      state.isFetching = false;
      state.isError = false;
      state.data = null;
      state.withLocalChanges = null;
      state.isEnabled = false;
    },
    setCourseLanguageTab: (state, {payload}) => {
      state.courseLanguageTab = payload;
    },
    resetSubscriptionChanges: state => {
      state.withLocalChanges = state.data;
      state.expiresValidationError = null;
      state.keyValidationError = null;
      state.isEnabled = Boolean(state.data);
    },
    updateFeatures: (state, {payload}) => {
      if (!state.withLocalChanges) {
        state.withLocalChanges = {};
      }
      state.withLocalChanges.features = payload;
    },
    updateCourses: (state, {payload}) => {
      if (!state.withLocalChanges) {
        state.withLocalChanges = {};
      }
      state.withLocalChanges.courses = payload;
    },
    updateExpiration: (state, {payload}) => {
      if (!state.withLocalChanges) {
        state.withLocalChanges = {};
      }
      state.withLocalChanges.expires = payload;
      state.expiresValidationError = validateExpires(state.withLocalChanges.expires);
    },
    updateKey: (state, {payload}) => {
      if (!state.withLocalChanges) {
        state.withLocalChanges = {};
      }
      state.withLocalChanges.key = payload;
      state.keyValidationError = validateKey(state.withLocalChanges.key);
    },
    updateCompanyName: (state, {payload}) => {
      state.withLocalChanges.companyName = payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadSubscription.pending, state => {
        state.isFetching = true;
        state.isError = false;
        state.errorMessage = null;
        state.expiresValidationError = null;
        state.keyValidationError = null;
      })
      .addCase(loadSubscription.rejected, (state, error) => {
        state.isError = true;
        state.errorMessage = error.error.message;
        state.isFetching = false;
      })
      .addCase(loadSubscription.fulfilled, (state, {payload}) => {
        state.isFetching = false;
        state.data = state.withLocalChanges = payload;
        state.isEnabled = Boolean(payload);
      })
  }
});

export const {
  setCourseLanguageTab,
  clearSubscription,
  updateFeatures,
  updateCourses,
  updateExpiration,
  updateKey,
  resetSubscriptionChanges,
  setEnabled,
  validateSubscription,
  updateCompanyName,
} = slice.actions;

export const currentSubscription = state => state.subscription.withLocalChanges;
export const subscriptionMeta = state => {
  return {
    isFetching: state.subscription.isFetching,
    isError: state.subscription.isError,
    errorMessage: state.subscription.errorMessage,
    courseLanguageTab: state.subscription.courseLanguageTab,
    isEnabled: state.subscription.isEnabled,
  }
}
export const subscriptionIsValid = state =>
  !(state.subscription.keyValidationError || state.subscription.expiresValidationError);

export const subscriptionUpdate = ({subscription: {data, withLocalChanges, isEnabled}}) => {
  let updateType;
  if (!isEnabled) {
    updateType = data
      ? SubscriptionUpdateType.DELETE
      : SubscriptionUpdateType.NOTHING;
  } else {
    if (!data) {
      updateType = SubscriptionUpdateType.CREATE;
    } else if (data.key !== withLocalChanges.key) {
      updateType = SubscriptionUpdateType.RECREATE;
    } else if (equal(withLocalChanges, data)) {
      updateType = SubscriptionUpdateType.NOTHING;
    } else {
      updateType = SubscriptionUpdateType.UPDATE;
    }
  }

  return {
    type: updateType,
    payload: {
      ...withLocalChanges,
      oldKey: data?.key
    }
  }
}

export default slice.reducer;

