import {Breadcrumbs, Typography} from "@material-ui/core";
import styles from "./Breadcrumbs.module.css";

const CompaniesBreadcrumbs = () => {
  return <Breadcrumbs aria-label="breadcrumb" className={styles.breadcrumbs}>
    <Typography color="textPrimary"> Companies </Typography>
  </Breadcrumbs>;
}

export default CompaniesBreadcrumbs;
