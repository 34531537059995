import {useDispatch, useSelector} from "react-redux";
import {
  Button,
  CircularProgress, Fab, Grid, Link,
  Paper, TextField, Typography,
} from "@material-ui/core";
import styles from "./Companies.module.css";
import {companiesState, filteredCompanies, loadCompanies, setFilter } from "./companiesSlice";
import {useEffect, Fragment} from "react";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from "react-router-dom";
import { setCurrentCompany } from "./currentCompanySlice";
import CompaniesBreadcrumbs from "./breadcrumbs/CompaniesBreadcrumbs";

export const Companies = () => {
  const state = useSelector(companiesState);
  const dispatch = useDispatch();
  const displayCompanies = useSelector(filteredCompanies);
  useEffect(() => {
    if (!displayCompanies.length && !state.isFetching) {
      dispatch(loadCompanies());
    }
  }, [dispatch, displayCompanies, state.isFetching]);
  const history = useHistory();
  const companyClicked = company => {
    dispatch(setCurrentCompany(company));
    history.push('/companies/' + company.id);
  }
  return <Fragment>
      <CompaniesBreadcrumbs />
      <Grid container spacing={1} alignItems="flex-end" className={styles.companyFilterContainer}>
        <Grid item>
          <SearchIcon />
        </Grid>
        <Grid item className={styles.companyFilterInputCell}>
          <TextField value={state.filter} onInput={e => {
            dispatch(setFilter(e.target.value))
          }} className={styles.companyFilterInput} id="input-with-icon-grid" label="Filter companies" />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="small"
            disabled={state.filter === ''}
            onClick={() => dispatch(setFilter(''))}
            startIcon={<ClearIcon />}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
      {(
        state.isFetching ?
          (<CircularProgress className={styles.loader}/>)
          : (
            <Grid className={styles.companiesGrid} container spacing={3} component="nav" aria-label="companies list">
              {
                displayCompanies
                  .map(company => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={3}
                      key={company.id}
                      onClick={() => companyClicked(company)}
                    >
                      <Paper className={styles.company} elevation={3} variant={company.subscriptionKey ? 'elevation' : 'outlined'}>
                        <Typography variant="h5" gutterBottom>
                          {(company.subscriptionKey ? <CardMembershipIcon fontSize='small' color="primary"/> : '')}
                          {company.name}
                        </Typography>
                        <Typography variant="subtitle1" className={styles.companyData}>
                          <LocationOnIcon fontSize='small'/>
                          <Link target="_blank" onClick={e => e.stopPropagation()} href={`https://maps.google.com/?q=${company.address}`}>{company.address}</Link>
                        </Typography>
                        <Typography variant="subtitle1" className={styles.companyData}>
                          <PhoneIcon fontSize='small'/>
                          <Link target="_blank" onClick={e => e.stopPropagation()} href={`tel:${company.phone}`}>{company.phone}</Link>
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
            </Grid>
          ))}
    <Fab className={styles.fab} color="primary" aria-label="add" onClick={() => history.push('/companies/new')}>
      <AddIcon />
    </Fab>
    </Fragment>
}
