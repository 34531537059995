import {Checkbox, FormControlLabel, Typography} from "@material-ui/core";

const descriptionParser = description => description
  .replaceAll('<size=18>', '')
  .replaceAll('</size>', '\n')
  .replaceAll('<$>', '\n')
  .replaceAll('<pos=5%><d>', ' *');

export const CourseLanguage = (props) => {
  const {
    courseIdAndLanguage,
    DisplayName,
    Description,
    courseProducts,
    subscribed,
    courseProductsChanged,
  } = props;
  return <>
    <Typography variant="h5" component="h5">{DisplayName}</Typography>
    <p style={{textAlign: 'left', whiteSpace: 'pre-wrap'}}>
      {descriptionParser(Description)}
    </p>
    <Typography variant="h6" component="h6">Bought Courses</Typography>
    {
      Object.entries(courseProducts)
        .map(([code, {name, id}]) => <FormControlLabel key={code}
          control={<Checkbox
            color='primary'
            value={id}
            key={`${courseIdAndLanguage}-${code}`}
            checked={subscribed && subscribed.indexOf(id) > -1}
            onChange={courseProductsChanged}
            name={id}
          />}
          label={name}
        />)
    }
  </>
}
