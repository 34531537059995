import {useDispatch, useSelector} from "react-redux";
import {useEffect, Fragment, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  Typography
} from "@material-ui/core";
import styles from "./Company.module.css";
import SaveIcon from "@material-ui/icons/Save";
import ReloadIcon from "@material-ui/icons/Refresh";
import {currentCompany, loadCompany, saveCompany} from "./currentCompanySlice";
import CompanyBreadcrumbs from "./breadcrumbs/CompanyBreadcrumbs";
import {Alert} from "@material-ui/lab";
import Subscription from "./subscription/Subscription";
import combineStates from "../../util/combineStates";
import {
  resetSubscriptionChanges,
  subscriptionUpdate as subscriptionUpdateSelector,
  subscriptionMeta as subscriptionMetaSelector, updateCompanyName,
} from "./subscription/subscriptionSlice";
import SubscriptionUpdateType from "./subscription/SubscriptionUpdateType";
import SubscriptionUpdateProcessor from "./subscription/SubscriptionUpdateProcessor";

export const Company = () => {
  const [state, setState] = useState({});
  const company = combineStates(useSelector(currentCompany), state);

  const companyId = useParams().id;
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyId !== 'new' && !company.id && !company.isFetching && !company.isError) {
      dispatch(loadCompany(companyId));
    }
  }, [company, companyId, dispatch]);

  const history = useHistory();

  useEffect(() => {
    if (companyId === 'new' && company.id) {
      history.push('/companies/' + company.id);
    }
  }, [companyId, company.id, history]);

  const changeProperty = ({name, value}) => {
    const newState = Object.assign({}, state);
    newState[name] = value;
    setState(newState);
  }

  const subscriptionMeta = useSelector(subscriptionMetaSelector);

  const subscriptionUpdate = useSelector(subscriptionUpdateSelector);

  const submitCompany = event => {
    event.preventDefault();

    // Validate subscription
    if (!(subscriptionMeta.expiresValidationError || subscriptionMeta.keyValidationError)) {
      // Update subscription key in company document if it was changed in the subscription
      if (
        subscriptionUpdate.type === SubscriptionUpdateType.RECREATE
        || subscriptionUpdate.type === SubscriptionUpdateType.CREATE
      ){
          company.subscriptionKey = subscriptionUpdate.payload.key;
      } else if (subscriptionUpdate.type === SubscriptionUpdateType.DELETE) {
        delete company.subscriptionKey;
      }
      subscriptionUpdate.payload.companyName = company.name;
      SubscriptionUpdateProcessor(subscriptionUpdate).then(
        () => console.log('Subscription update processed')
      );

      const newState = Object.assign({}, company, { wasSaving: true, snackbarClosed: false });
      dispatch(saveCompany(company));
      setState(newState);
    }
  };

  const companyLoading = company.isFetching;
  return <Fragment>
    <CompanyBreadcrumbs name={company.name} />
    <form noValidate className={styles.CompanyForm} onSubmit={submitCompany}>
      <Typography variant="h4" component="h4" gutterBottom>
        Company Details
      </Typography>
      {( companyLoading
        ? (<CircularProgress className={styles.loader}/>)
        : ( <Fragment>
          <input type="hidden" name="id" id="id" value={companyId || ''}/>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Company Name"
            name="name"
            helperText="Can't be empty"
            value={company?.name || ''}
            onInput={({target: {value}}) => {
              changeProperty({name: 'name', value});
              dispatch(updateCompanyName(value));
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="address"
            label="Address"
            id="address"
            value={company?.address || ''}
            onInput={e => changeProperty({name: 'address', value: e.target.value})}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="phone"
            label="Phone"
            id="phone"
            value={company?.phone || ''}
            onInput={e => changeProperty({name: 'phone', value: e.target.value})}
          />
          </Fragment>
        )
      )}
      <Subscription
        companySubscriptionKey={company.subscriptionKey}
        updateSubscriptionKey={value => changeProperty({name: 'subscriptionKey', value})}
      />
      {/*{ state.isError ? <MuiAlert className={styles.SignInError} elevation={6} variant="filled" severity="error"> {state.errorMessage} </MuiAlert> : '' }*/}
      <div className={styles.buttonsContainer}>
        <Button
          variant="contained"
          disabled={companyLoading}
          size="large"
          startIcon={<ReloadIcon/>}
          onClick={() => {
            setState({});
            dispatch(resetSubscriptionChanges());
          }}
        >
          Reset changes
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={companyLoading}
          size="large"
          startIcon={<SaveIcon/>}
        >
          Save changes
        </Button>
      </div>
    </form>
    <Snackbar open={!company.isFetching && company.wasSaving && !state.snackbarClosed} autoHideDuration={1000}>
      <Alert severity="success" onClose={_ => setState(Object.assign({}, state, {snackbarClosed: true}))}>Saved successfully.</Alert>
    </Snackbar>
  </Fragment>
}
