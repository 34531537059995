import firebase from "firebase";
import {initFirebase} from "./index";

initFirebase();

const db = firebase.firestore();

const companiesRef = db.collection('Companies');
const subscriptionsRef = db.collection('Subscriptions');
const coursesRef = db.collection('CoursesNew');

const companyFromFirestore = doc => {
  const data = doc.data();
  return {
    id: doc.id,
    name: data.CompanyName,
    address: data.Address,
    phone: data.Phone,
    subscriptionKey: data.SubscriptionKey
  }
}

const companyToFirestore = company => {
  const result = {
    CompanyName: company.name.trim()
  }
  if (company.address !== null) {
    result.Address = company.address.trim();
  }
  if (company.phone !== null) {
    result.Phone = company.phone.trim();
  }
  if (
    typeof company.subscriptionKey !== 'undefined'
    && company.subscriptionKey !== null
    && company.subscriptionKey !== ''
  ) {
    result.SubscriptionKey = company.subscriptionKey.trim();
  }
  return result;
}

export const saveCompany = async company => {
  const exported = companyToFirestore(company);
  return await company.id
    ? companiesRef.doc(company.id).set(exported)
    : companiesRef.add(exported);
}

export const getCompanies = async () => {
  const snapshot = await companiesRef
    .get();
  return snapshot.docs.map(doc => companyFromFirestore(doc));
}
export const getCompany = async (id) => {
  const doc = await companiesRef
    .doc(id)
    .get();
  if (!doc.exists) {
    return null;
  }
  return companyFromFirestore(doc);
}

export const getCourses = async () => {
  const snapshot = await coursesRef
    .get();
  return snapshot.docs.map(doc => {
    const data = doc.data();
    const jsonData = JSON.parse(data.CourseJson);
    return {
      id: doc.id,
      category: data.CategoryName,
      ...jsonData
    }
  });
}

export const getSubscription = async key => {
  const doc = await subscriptionsRef
    .doc(key)
    .get();
  if (!doc.exists) {
    return null;
  }
  const data = doc.data();
  const dateSplit = data.ExpirationDate.split('/');
  return {
    key: key,
    expires: `${dateSplit[2]}-${dateSplit[0]}-${dateSplit[1]}`,
    courses: data.CourseIds,
    features: data.FeatureIds,
    companyName: data.CompanyName
  }
}

const formatSubscriptionData = payload => {
  const dateSplit = payload.expires.split('-');

  return {
    CompanyName: payload.companyName,
    CourseIds: payload.courses,
    FeatureIds: payload.features,
    ExpirationDate: `${dateSplit[1]}/${dateSplit[2]}/${dateSplit[0]}`
  }
}

export const updateSubscription = async payload => {
  await deleteSubscription(payload);
  return createSubscription(payload);
}

export const createSubscription = async payload => {
  return subscriptionsRef.doc(payload.key).set(formatSubscriptionData(payload))
}

export const deleteSubscription = async ({oldKey}) => {
  return await subscriptionsRef.doc(oldKey).delete();
}
