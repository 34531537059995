import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { initFirebase } from "../../firebase-services";
import {getCompanies} from "../../firebase-services/firestore";

initFirebase();

export const loadCompanies = createAsyncThunk(
  'companies/loadCompanies',
  async () => {
    return await getCompanies();
  }
)

export const slice = createSlice({
  name: 'companies',
  initialState: {
    page: 0,
    filter: '',
    isFetching: false,
    isError: false,
    errorMessage: '',
    allCompanies: [],
    displayedCompanies: [],
    allCourses: [],
  },
  reducers: {
    setFilter: (state, {payload}) => {
      state.filter = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCompanies.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(loadCompanies.rejected, (state, error) => {
        state.isError = true;
        state.errorMessage = error.error.message;
        state.isFetching = false;
      })
      .addCase(loadCompanies.fulfilled, (state, {payload}) => {
        state.isFetching = false;
        state.allCompanies = payload;
      });
  },
});

export const { setFilter, } = slice.actions;

export const companiesState = state => state.companies;

const NOT_DIGIT = /[^\d]/g

export const filteredCompanies = ({companies}) => {
  const {allCompanies, filter} = companies;
  const digitsOnlyFromFilter = filter.replaceAll(NOT_DIGIT, '');
  return allCompanies ? allCompanies.filter(company => {
    return filter === ''
      || company.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
      || company.address.toLowerCase().indexOf(filter.toLowerCase()) > -1
      || (digitsOnlyFromFilter !== '' && company.phone.replaceAll(NOT_DIGIT, '').indexOf(digitsOnlyFromFilter) > -1)
  }) : [];
}

export default slice.reducer;
