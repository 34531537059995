import {useDispatch, useSelector} from "react-redux";
import {isLoggedInSelector, signOut} from "../auth/authSlice";
import {AppBar, Button, Toolbar, Typography} from "@material-ui/core";
import styles from './NavBar.module.css';

export const NavBar = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(isLoggedInSelector);
  return loggedIn
    ? (
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography className={styles.heading} variant="h6" color="inherit">
            Hard Hat VR
          </Typography>
          <Button color="inherit" onClick={() => {
            dispatch(signOut());
          }}>Sign out</Button>
        </Toolbar>
      </AppBar>
    ) : '';
}
