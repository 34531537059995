import './App.css';

import {SignIn} from "./features/auth/SignIn";
import {NavBar} from "./features/nav/NavBar";
import {Companies} from "./features/companies/Companies";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {useSelector} from "react-redux";
import {isLoggedInSelector} from "./features/auth/authSlice";
import {Company} from "./features/companies/Company";
import {FirebaseAuth} from "./features/auth/FirebaseAuth";

function App() {

  return (
    <FirebaseAuth>
      <Router>
        <div className="App">
          <NavBar />
          <Switch>
            <Route path="/" exact>
              <HomeRedirect />
            </Route>
            <NoAuthRoute path="/signin" exact>
              <SignIn />
            </NoAuthRoute>
            <PrivateRoute path="/companies" exact>
              <Companies />
            </PrivateRoute>
            <PrivateRoute path="/companies/:id" children={<Company />}/>
            <Route path="*">
              <HomeRedirect />
            </Route>
          </Switch>
        </div>
      </Router>
    </FirebaseAuth>
  );
}

function HomeRedirect() {
  let loggedIn = useSelector(isLoggedInSelector);
  return <Redirect
    to={{
      pathname: loggedIn ? '/companies' : '/signin'
    }}
  />
}

function NoAuthRoute({ children, ...rest }) {
  let loggedIn = useSelector(isLoggedInSelector);
  return (
    <Route
      {...rest}
      render={() =>
        !loggedIn ? (
          children
        ) : (
          <Redirect
            to={{pathname: '/'}}
          />
        )
      }
    />
  );
}

function PrivateRoute({ children, ...rest }) {
  let loggedIn = useSelector(isLoggedInSelector);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        loggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default App;
