import {Fragment} from "react";
import styles from "./FirebaseAuth.module.css";
import {CircularProgress} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {authLoaded, authState} from "./authSlice";
import {auth} from "../../firebase-services/auth";
import {coursesLoading, loadCourses} from "../courses/coursesSlice";

export const FirebaseAuth = props => {

  const state = useSelector(authState);
  const coursesFetching = useSelector(coursesLoading);
  const dispatch = useDispatch();

  auth.onAuthStateChanged(user => {
    dispatch(authLoaded(Boolean(user)));
    if (user && !coursesFetching) {
      dispatch(loadCourses());
    }
  });

  return state.isInitialized
    ? <Fragment>{props.children}</Fragment>
    : <CircularProgress className={styles.loader}/>
}
