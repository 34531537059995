import {
  Accordion, AccordionDetails, AccordionSummary, Badge,
  Checkbox,
  CircularProgress, FormControl,
  FormControlLabel,
  FormGroup,
  Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, Switch,
  TextField,
  Typography
} from "@material-ui/core";
import styles from "../Company.module.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TimerIcon from "@material-ui/icons/Timer";
import {Course} from "../../courses/Course";
import {useDispatch, useSelector} from "react-redux";
import {
  clearSubscription,
  currentSubscription,
  loadSubscription,
  setCourseLanguageTab, setEnabled, subscriptionMeta as subscriptionMetaSelector,
  updateCourses, updateExpiration, updateFeatures, updateKey
} from "./subscriptionSlice";
import {useEffect, Fragment, useState} from "react";
import {allCourses} from "../../courses/coursesSlice";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {Alert} from "@material-ui/lab";

const generateSubscriptionKey = () => window.crypto.getRandomValues(new Uint8Array(16))
  .map(num => Math.floor(num / 256 * 35) )
  .map(num => num + (num < 10 ? 48 : 55) )
  .reduce((acc, val) => acc + String.fromCharCode(val), "");

const features = [
  'dashboard',
  'reports',
  'settings',
  'users',
  'backups',
  'games'
];

const getNumberOfPurchasedCourseProducts = (courseIds, subscription) => subscription?.courses
  ? courseIds.filter(id => subscription.courses.indexOf(id) > -1).length
  : 0;

const Subscription = props => {
  const dispatch = useDispatch();
  let subscription = useSelector(currentSubscription);
  const subscriptionMeta = useSelector(subscriptionMetaSelector);
  const {companySubscriptionKey} = props;
  const [copyOpen, setCopyOpen] = useState(false);
  const subscriptionLoading = subscriptionMeta.isFetching;
  useEffect(() => {
    if (
      !subscriptionLoading
      && companySubscriptionKey
      && (!subscription || subscription?.key !== companySubscriptionKey)
    ) {
      dispatch(loadSubscription(companySubscriptionKey));
    } else if (
      !subscriptionLoading
      && !companySubscriptionKey
      && subscription
    ) {
      dispatch(clearSubscription());
    }
  }, [dispatch, companySubscriptionKey, subscription, subscriptionLoading]);

  const featureChanged = ({target: {name}}, value) => {
    let features = subscription?.features?.slice() ?? [];
    if (value) {
      features.push(name);
    } else {
      features = features.filter(feature => feature !== name);
    }
    dispatch(updateFeatures(features));
  }
  const courseChanged = ({target: {name}}, value) => {
    let courses = subscription?.courses?.slice() ?? [];
    if (value) {
      courses.push(name);
    } else {
      courses = courses.filter(course => course !== name);
    }
    dispatch(updateCourses(courses));
  }
  const expirationChanged = ({target: {value}}) => {
    dispatch(updateExpiration(value));
  };

  const keyChanged = value => {
    props.updateSubscriptionKey(value);
    dispatch(updateKey(value));
  }

  const {courseLanguageTab} = subscriptionMeta;
  const courses = useSelector(allCourses);

  const changeLanguageTab = value => {
    dispatch(setCourseLanguageTab(value));
  }

  return <Fragment>{(subscriptionLoading
    ? (<CircularProgress className={styles.loader}/>)
    : (<FormGroup style={{marginTop: '1em'}}>
        <Typography variant="h4" component="h4" gutterBottom>
          Subscription
        </Typography>
        <FormControlLabel
          style={{margin: "0 auto"}}
          control={
            <Switch
              checked={subscriptionMeta.isEnabled}
              onChange={(_, v) => dispatch(setEnabled(v))}
              name="subscriptionEnable"
              color="primary"
            />
          }
          label="Company is Subscribed"
        />
      {subscriptionMeta.isEnabled
        ? (
        <Fragment>
        <FormControl>
        <InputLabel shrink={true} htmlFor="subscription-key">Subscription Key</InputLabel>
        <OutlinedInput
          error={subscriptionMeta.keyValidationError}
          helperText={subscriptionMeta.keyValidationError}
          id="subscription-key"
          required
          fullWidth
          name="key"
          disabled={subscriptionLoading}
          value={subscription?.key || ''}
          onInput={e => keyChanged(e.target.value)}
          style={{
            letterSpacing: "0.15em",
            fontFamily: "monospace",
          }}
          endAdornment={
            <Fragment>
            <InputAdornment position="end">
              <IconButton
                aria-label="Generate random key"
                onClick={() => keyChanged(generateSubscriptionKey())}
                edge="end"
                title="Generate random key"
              >
                <AutorenewIcon />
              </IconButton>
            </InputAdornment>
            <InputAdornment position="end">
              <IconButton
                aria-label="Copy subscription key"
                onClick={() => {
                  document.querySelector('input[name="key"]').select();
                  document.execCommand('copy');
                  setCopyOpen(true);
                }}
                edge="end"
                title="Copy subscription key"
              >
                <FileCopyIcon />
              </IconButton>
            </InputAdornment>
            </Fragment>
          }
        />
        </FormControl>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="date"
          name="expires"
          label="Expires"
          id="expires"
          placeholder=""
          disabled={subscriptionLoading}
          InputLabelProps={{shrink: true}}
          value={subscription?.expires || ''}
          onInput={expirationChanged}
          error={subscriptionMeta.expiresValidationError}
          helperText={subscriptionMeta.expiresValidationError}
        />
        <Typography variant="h5" component="h5" gutterBottom>
          Features
        </Typography>
        <Grid container spacing={3} className={styles.featuresList}>
          {
            features.map(feature => <Grid item xs key={feature}>
              <FormControlLabel
                label={feature}
                control={<Checkbox
                  color='primary'
                  value={feature}
                  checked={subscription?.features && subscription.features.indexOf(feature) > -1}
                  onChange={featureChanged}
                  name={feature}
                />}
              />
            </Grid>)
          }
        </Grid>
        <Typography variant="h5" component="h5" gutterBottom>
          Courses
        </Typography>
        {
          courses
            .map(course => <Accordion key={`course-${course.id}`}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id={`course-${course.id}`}
              >
                <Typography>{course.DisplayName}</Typography>
                <Badge color="primary" badgeContent={getNumberOfPurchasedCourseProducts(Object.values(course.CourseIds), subscription)}/>
                <TimerIcon fontSize="small" style={{margin: '1px 2px 0 auto'}}/>
                <Typography>{course.CourseTime}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Course
                  languageTab={courseLanguageTab}
                  changeLanguageTab={changeLanguageTab}
                  course={course}
                  subscribed={subscription?.courses ?? null}
                  courseProductsChanged={courseChanged}
                />
              </AccordionDetails>
            </Accordion>)
        }
        </Fragment>) : ''}
      </FormGroup>
    )
  )}
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={copyOpen}
      autoHideDuration={1000}
      handleClose={() => setCopyOpen(false)}
      onClose={() => setCopyOpen(false)}>
      <Alert onClose={() => setCopyOpen(false)} severity="info">
        Key copied to clipboard.
      </Alert>
    </Snackbar>
  </Fragment>;
}

export default Subscription;
