import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { initFirebase } from "../../firebase-services";
import {getCompany, saveCompany as saveCompanyToFirestore} from "../../firebase-services/firestore";

initFirebase();

export const loadCompany = createAsyncThunk(
  'currentCompany/loadCompany',
  async id => await getCompany(id)
)

let companyChanges;

export const saveCompany = createAsyncThunk(
  'currentCompany/saveCompany',
  async company => {
    companyChanges = company;
    return await saveCompanyToFirestore(company);
  }
)

export const slice = createSlice({
  name: 'currentCompany',
  initialState: {
    id: null,
    name: '',
    address: '',
    phone: '',
    subscriptionKey: '',
    isError: false,
    isFetching: false,
    errorMessage: null,
    changes: {},
  },
  reducers: {
    setCurrentCompany: (state, {payload}) => {
      state.id = payload.id;
      state.name = payload.name;
      state.address = payload.address;
      state.phone = payload.phone;
      state.subscriptionKey = payload.subscriptionKey;
      state.isError = false;
      state.isFetching = false;
      state.errorMessage = null;
    },
    setId: (state, {payload}) => {
      state.id = payload;
    },
    clearCurrentCompany: state => {
      state.id = null;
      state.name = null;
      state.address = null;
      state.phone = null;
      state.subscriptionKey = null;
      state.isError = false;
      state.isFetching = false;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadCompany.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
        state.errorMessage = null;
      })
      .addCase(loadCompany.rejected, (state, error) => {
        state.isError = true;
        state.errorMessage = error.error.message;
        state.isFetching = false;
      })
      .addCase(loadCompany.fulfilled, (state, {payload}) => {
        state.isFetching = false;
        state.id = payload.id;
        state.name = payload.name;
        state.address = payload.address;
        state.phone = payload.phone;
        state.subscriptionKey = payload.subscriptionKey;
      })
      .addCase(saveCompany.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
        state.errorMessage = null;
      })
      .addCase(saveCompany.rejected, (state, error) => {
        state.isError = true;
        state.errorMessage = error.error.message;
        state.isFetching = false;
      })
      .addCase(saveCompany.fulfilled, (state, {payload}) => {
        state.isFetching = false;
        if (payload && payload.id) {
          state.id = payload.id;
        }
        state.name = companyChanges.name;
        state.address = companyChanges.address;
        state.phone = companyChanges.phone;
        state.subscriptionKey = companyChanges.subscriptionKey;
      });
  }
});

export const {
  setCurrentCompany,
  clearCurrentCompany,
} = slice.actions;

export const currentCompany = state => state.currentCompany;


export default slice.reducer;
