import firebase from "firebase";
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';

let initialized = false;

export const initFirebase = () => {
  if (!initialized) {
    firebase.initializeApp({
      apiKey: "AIzaSyDQrmXK5b6t8AKC7XZzYGwx8f2cWXx6z6Q",
      authDomain: "hhvr-launcher-test-1.firebaseapp.com",
      databaseURL: "https://hhvr-launcher-test-1.firebaseio.com/",
      projectId: "hhvr-launcher-test-1",
      storageBucket: "hhvr-launcher-test-1.appspot.com",
      messagingSenderId: "200098871496",
      appId: "1:200098871496:web:20a5f7e257e7014a008dfd",
      measurementId: "G-X2VT7J1Q7T"
    });
    initialized = true;
  }
}

// export const analytics = firebase.analytics();
// export const firestore = firebase.firestore();
