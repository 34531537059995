import firebase from "firebase";
import {initFirebase} from "./index";

initFirebase();
export const auth = firebase.auth();

export const authProvider = new firebase.auth.EmailAuthProvider();
export const signInWithEmailAndPassword = (email, password, remember) => {
  return auth
    .setPersistence(
      remember
        ? firebase.auth.Auth.Persistence.LOCAL
        : firebase.auth.Auth.Persistence.NONE
    ).then(() => {
      return auth.signInWithEmailAndPassword(email, password);
    });
}

export const signOutOfFirebase = async () => {
  return await auth.signOut();
}
