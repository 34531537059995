import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import companiesReducer from '../features/companies/companiesSlice';
import currentCompanyReducer from '../features/companies/currentCompanySlice';
import subscriptionReducer from '../features/companies/subscription/subscriptionSlice';
import coursesReducer from '../features/courses/coursesSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    companies: companiesReducer,
    currentCompany: currentCompanyReducer,
    subscription: subscriptionReducer,
    courses: coursesReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: [
        'auth/attemptSignIn/fulfilled',
        // 'companies/loadCompanies/fulfilled',
      ],
    }
  }),
});
