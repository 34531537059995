import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { initFirebase } from "../../firebase-services";
import {getCourses} from "../../firebase-services/firestore";

initFirebase();

export const loadCourses = createAsyncThunk(
  'courses/loadCourses',
  async () => {
    return await getCourses();
  }
)

export const slice = createSlice({
  name: 'courses',
  initialState: {
    isFetching: false,
    isError: false,
    errorMessage: null,
    allCourses: [],
  },
  reducers: {
    setFilter: (state, {payload}) => {
      state.filter = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCourses.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(loadCourses.rejected, (state, error) => {
        state.isError = true;
        state.errorMessage = error.error.message;
      })
      .addCase(loadCourses.fulfilled, (state, {payload}) => {
        state.allCourses = payload;
      });
  },
});

export const allCourses = ({courses}) => courses.allCourses;
export const coursesLoading = ({courses}) => courses.isFetching;

export default slice.reducer;
