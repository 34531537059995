import {Badge, Tab} from "@material-ui/core";
import {CourseLanguage} from "./CourseLanguage";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";

const courseProducts = {
  EnCourse: 'English Course',
  EnVrSim: 'English VR Sim',
  EnDeskSim: 'English Desk Sim',
  EsCourse: 'Spanish Course',
  EsVrSim: 'Spanish VR Sim',
  EsDeskSim: 'Spanish Desk Sim',
}

const formatCourseProducts = (langPrefix, language, courseIds) => Object.fromEntries(
  Object.entries(courseProducts)
    .filter(([code, name]) => code.indexOf(langPrefix) === 0)
    .map(([code, name]) => [code, name.replace(language + ' ', '')])
    .map(
      ([code, name]) => [code, {
        name,
        id: courseIds[code]
      }]
    )
)

const getNumberOfPurchasedCourseProducts = (courseProducts, subscribed) => {
  return Object.entries(courseProducts)
    .filter(([_, {__, id}]) => subscribed && subscribed.indexOf(id) > -1)
    .length;
}

export const Course = props => {
  const {course, subscribed, languageTab, changeLanguageTab, courseProductsChanged} = props;
  const enCourseProducts = formatCourseProducts('En', 'English', course.CourseIds);
  const esCourseProducts = formatCourseProducts('Es', 'Spanish', course.CourseIds);
  return <div style={{flexDirection: 'column', width: '100%'}}>
    <TabContext value={languageTab}>
      <TabList
        value={languageTab}
        onChange={(_, value) => changeLanguageTab(value)}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={
          <Badge color="primary" badgeContent={getNumberOfPurchasedCourseProducts(enCourseProducts, subscribed)}>
            English
          </Badge>
        } value='En' />
        <Tab label={
          <Badge color="primary" badgeContent={getNumberOfPurchasedCourseProducts(esCourseProducts, subscribed)}>
            Spanish
          </Badge>
        } value='Es' />
      </TabList>
      <TabPanel value='En' index={0}>
        <CourseLanguage
          {...course.CourseDescriptions.En}
          courseIdAndLanguage={`${course.id}-es`}
          subscribed={subscribed}
          courseProductsChanged={courseProductsChanged}
          courseProducts={enCourseProducts}
        />
      </TabPanel>
      <TabPanel value='Es' index={1}>
        <CourseLanguage
          {...course.CourseDescriptions.Es}
          courseIdAndLanguage={`${course.id}-es`}
          subscribed={subscribed}
          courseProductsChanged={courseProductsChanged}
          courseProducts={esCourseProducts}
        />
      </TabPanel>
    </TabContext>
  </div>
}
